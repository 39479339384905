// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$moodie-dashboard-primary: mat-palette($mat-blue-grey);
$moodie-dashboard-accent: mat-palette($mat-blue, A200, A100, A400);

// The warn palette is optional (defaults to red).
$moodie-dashboard-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$moodie-dashboard-theme: mat-light-theme(
  (
    color: (
      primary: $moodie-dashboard-primary,
      accent: $moodie-dashboard-accent,
      warn: $moodie-dashboard-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($moodie-dashboard-theme);

/* You can add global styles to this file, and also import other style files */

@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import '~ngx-sharebuttons/themes/default/default-theme';

@import "~ng-zorro-antd/ng-zorro-antd.min.css"; // Import app styles
// 1 - HELPERS
@import "./1-helpers/functions";
@import "./1-helpers/mixins";
@import "./1-helpers/vars";

// 2 - TOOLS
@import "./2-tools/animations";
@import "./2-tools/slider";
@import "./2-tools/spinner";

// 3 - BASICS
@import "3-basics/base";
@import "3-basics/buttons";
@import "3-basics/forms";
@import "3-basics/links";
@import "3-basics/pagination";
@import "3-basics/slider";
@import "3-basics/stepper";
@import "3-basics/table";
@import "3-basics/typography";
@import "3-basics/panel";
@import "3-basics/loading";

// 4 - LAYOUTS
@import "4-layouts/page";

// 5 - COMPONENTS
@import "components/card";
@import "components/thread";

@import "./themes/light.scss";
@import "./themes/dark.scss";

// 5 - COMPONENTS
@import "components/card";
@import "components/thread";

@font-face {
  font-family: "Century-Gothic";
  src: url("/assets/fonts/GOTHIC.TTF");
}

@font-face {
  font-family: "Gen Jyuu Gothic";
  src: url("/assets/fonts/GeJyuuGothic.ttf");
}

@font-face {
  font-family: "NotoSerifHK";
  src: url("/assets/fonts/NotoSerifHK-Regular.ttf");
}

body {
  *:not(.mat-icon) {
    font-family: Century Gothic, CenturyGothic, Century-Gothic, AppleGothic, NotoSerifHK,
      sans-serif !important;
  }

  input,
  label {
    line-height: 18px !important;
  }
}

input:-internal-autofill-selected,
input:-internal-autofill-selected:hover,
input:-internal-autofill-selected:focus,
input:-internal-autofill-selected:active,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #fff;
  transition: background-color 5000s ease-in-out 0s;
}
