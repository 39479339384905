.page-layout {
  font-size: 1rem;
  width: 100%;
  max-width: 100%;
  padding-top: 30px;
  color: #ffffff;

  > * {
    padding: 0 30px 0 30px;
    margin: 5px 0;
    &.m--s-padding {
      padding: 0 15px;
      margin: 0;
    }
  }

  .action {
    width: 100%;
  }

  .banner {
    width: 100%;
    background: rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(5px);
    padding-bottom: 20px;

    .left-bar {
      margin: 0;
      padding: 0.5em 0.5em 0;
      border-left: 9px rgba(255, 255, 255, 0.5) solid;
    }
  }
}

.m-layout-container {
  &-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  .m-layout--space-between {
    justify-content: space-between;
  }
  .m-layout-column-3 {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 15px;
  }
  .m-layout-column-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding: 0 15px;
  }
  .m-layout-column-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
  }
  .m-layout-column-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px;
  }
}

.m-subadmin-thread-wrap {
  padding: 30px 0;
}

.page-container {
  padding: 25px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .page-header {
    h1 {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.5px;
      font-weight: 600;
      text-transform: uppercase;
      margin: 0;
    }
  }
  .page-content {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    min-height: 250px;
    flex-grow: 1;
    flex-shrink: 0;
  }
}

.m-container {
  padding-right: map-get($padding, container);
  padding-left: map-get($padding, container);
}

.m-row {
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  margin-right: -1 * map-get($padding, container);
  margin-left: -1 * map-get($padding, container);
  .m-col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .m-col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .m-col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .m-col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.m-col,
.m-col-1,
.m-col-10,
.m-col-11,
.m-col-12,
.m-col-2,
.m-col-3,
.m-col-4,
.m-col-5,
.m-col-6,
.m-col-7,
.m-col-8,
.m-col-9,
.m-col-auto,
.m-col-lg,
.m-col-lg-1,
.m-col-lg-10,
.m-col-lg-11,
.m-col-lg-12,
.m-col-lg-2,
.m-col-lg-3,
.m-col-lg-4,
.m-col-lg-5,
.m-col-lg-6,
.m-col-lg-7,
.m-col-lg-8,
.m-col-lg-9,
.m-col-lg-auto,
.m-col-md,
.m-col-md-1,
.m-col-md-10,
.m-col-md-11,
.m-col-md-12,
.m-col-md-2,
.m-col-md-3,
.m-col-md-4,
.m-col-md-5,
.m-col-md-6,
.m-col-md-7,
.m-col-md-8,
.m-col-md-9,
.m-col-md-auto,
.m-col-sm,
.m-col-sm-1,
.m-col-sm-10,
.m-col-sm-11,
.m-col-sm-12,
.m-col-sm-2,
.m-col-sm-3,
.m-col-sm-4,
.m-col-sm-5,
.m-col-sm-6,
.m-col-sm-7,
.m-col-sm-8,
.m-col-sm-9,
.m-col-sm-auto,
.m-col-xl,
.m-col-xl-1,
.m-col-xl-10,
.m-col-xl-11,
.m-col-xl-12,
.m-col-xl-2,
.m-col-xl-3,
.m-col-xl-4,
.m-col-xl-5,
.m-col-xl-6,
.m-col-xl-7,
.m-col-xl-8,
.m-col-xl-9,
.m-col-xl-auto {
  padding-right: map-get($padding, container);
  padding-left: map-get($padding, container);
}
