.slider-circles {
  text-align: center;
  white-space: nowrap;

  .circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 999px;
    background-color: rgba($color: #ffffff, $alpha: 0.6);
    white-space: nowrap;

    transition: all 0.3s;

    &:hover,
    &:active,
    &.active {
      background-color: rgba($color: #ffffff, $alpha: 1);
    }
  }
}
.slider-navigation {
  text-align: right;
  white-space: nowrap;

  .arrow-left,
  .arrow-right {
    display: inline-block;
    margin: 0 10px;
    color: rgba($color: #ffffff, $alpha: 0.6);
    transition: all 0.3s;
    cursor: pointer;

    &:hover,
    &:active {
      color: rgba($color: #ffffff, $alpha: 1);
    }
  }
}
.slider-content {
  .slider-content-title {
    margin-top: 0;
    font-size: 1.65em;
  }
  .slider-content-info {
  }
  .slider-content-description {
  }
}

.read-more {
  font-size: 1.5em;
  text-align: right;
  margin-top: 10px;
  position: absolute;
  bottom: 12px;
  right: 12px;
  font-size: 16px;
}
