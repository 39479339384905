/* font faces*/

body,
a,
p,
h1, h2, h3, h4, h5, h6,
button {
  font-family: roboto, Roboto, sans-serif;
  font-weight: lighter;
}
.info-text {
  font-size: .65em;
  color: rgba($color: #ffffff, $alpha: .8);
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
