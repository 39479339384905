a {
  text-decoration: none;
  color: currentColor;
  cursor: pointer;

  &:active {
    filter: brightness(0.8);
  }

  &.hyper-link:hover {
    text-decoration: underline !important;
  }
}

a.light-link {
  color: #b5b5b5;
  transition: all 0.3s;

  &:hover {
    color: #ffffff;
  }
}
