$color-primary: #0173d5 !default;
$color-secondary: #62b6ff !default;
$color-error: #f44336 !default;

$colors: (
  "primary": #0173d5,
  "secondary": #62b6ff,
  "error": #f44336,
  "borderWrap": #ffffff
);

$bg-colors: (
  primary: #434d5f,
  hover: #00ffd2,
);
$bgs: (
  "header": #ffffff,
  "footer": #121312,
  "portfolio": #f4f7ed,
);

$fonts: (
  primary: #fff,
  hover: #333,
);

$padding: (
  container: 15px,
);