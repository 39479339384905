@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  font-size: 1.5em;
  right: .5em;
  width: 1em;
  height: 1em;
  margin-top: -.5em;
  margin-left: -.5em;
  border-radius: 50%;
  border: 0.1em solid currentColor;
  border-top-color: transparent;
  animation: spinner .8s linear infinite;
}
