html,
body {
  font-size: 18px;
  position: relative;
  height: 100%;
}
body {
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

code {
  background-color: #f0f0f0;
  border-radius: 3px;
  padding: 0.25em 0.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // margin: 0;
  color: white !important;
}
label {
  cursor: pointer;
}
img {
  max-width: 100%;
  max-height: 100%;
}

.error {
  color: $color-error;
}

.flex-wrapper {
  display: flex;
  width: 100%;

  &.flex-larger-gap {
    margin-bottom: 20px;

    > .flex-item + .flex-item {
      margin-left: 20px;
    }
  }
  &.flex-vertical {
    flex-direction: column;

    > .flex-item + .flex-item {
      margin-left: 0;
    }
  }
  &.flex-even {
    > .flex-item {
      flex: 1;
    }
  }
  &.flex-space-between {
    justify-content: space-between;
  }
  &.flex-same-height {
    > .flex-item {
      align-self: stretch;
    }
  }
  &.flex-item-center {
    align-items: center;
  }
  &.flex-center {
    justify-content: center;
    align-items: center;
  }

  > .flex-item {
    &.flex-1 {
      flex: 1;
    }
    &.flex-2 {
      flex: 2;
    }
  }
  > .flex-item + .flex-item {
    margin-left: 10px;
  }
  .flex-all-space {
    flex: 1;
  }
}

.comparisonlegend {
  display: inline-block;
  width: 19px;
  height: 30px;
  background: #00ff94;
  border-radius: 10px;
  transform: rotate(-180deg);
}
.start-new-project {
  display: block;
  position: relative;
  min-height: 100px;
  text-align: center;
  cursor: pointer;
}

.alert-error {
  background-color: white;

  .mat-simple-snackbar {
    span {
      color: white;
      font-size: 14px;
    }
  }
}

.alert-success {
  background-color: white;

  .mat-simple-snackbar {
    span {
      color: white;
      font-size: 14px;
    }
  }
}

.no-result {
  text-align: center;
  font-size: 14px;
  line-height: 100px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.mat-table {
  margin-top: 15px;
}

.cdk-global-overlay-wrapper {
  align-items: center !important;
  background-color: rgb(0, 0, 0, 50%);

  .mat-snack-bar-container {
    background-color: #20282e;
  }

  .mat-simple-snackbar {
    justify-content: center;
  }
}
