.blur-table {
  width: 100%;

  .mat-table {
    width: 100%;
    background-color: transparent;
    color: #fff;
    border-spacing: 0 10px;

    .mat-header-row {
      height: auto;
    }
    .mat-row {
      cursor: pointer;
    }

    .mat-header-cell,
    .mat-cell {
      color: #fff;
      padding: 0 10px;
    }
    .mat-header-cell {
      border: 0;
      padding-bottom: 10px;
    }
    .mat-cell {
      position: relative;
      border: 1px #ffffff solid;
      // background-color: rgba($color: #757575, $alpha: 0.7);
      border-width: 1px 0;

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        // border-left-width: 1px;
      }
      &:last-child {
        // border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        // border-right-width: 1px;
      }
      + .mat-cell {

        &::before {
          content: "";
          position: absolute;
          background-color: rgba($color: #ffffff, $alpha: 0.2);
          width: 1px;
          height: 2em;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
