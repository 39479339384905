.m-panel {
  background-color: #1a242b;
  border-radius: 3px;
  border: 1px solid #9eacb9;
  box-shadow: 0 4px 6px rgb(51 51 51 / 31%);
  box-shadow: 0 1px 3px rgb(51 51 51 / 15%);
  box-shadow: 0 0 3px rgb(0 0 0 / 10%);
  border-radius: 3px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .m-panel-header {
    border-bottom: 1px solid #9eacb9;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 15px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0;
    color: #fff;
    background-color: #3b3d77;
    background-image: linear-gradient(100deg, #2b2f5d, #4b4b90);
    border: 0;
    h3 {
      font-size: 14px;
      text-transform: uppercase;
      margin: 0;
    }
  }
  .m-panel-body {
    padding: 15px 25px;
    flex-shrink: 0;
    flex-grow: 1;
  }
}
