mat-horizontal-stepper.mat-stepper-horizontal {
  background-color: transparent;

  .mat-horizontal-stepper-header-container {
    display: none !important;
  }

  .mat-horizontal-content-container {
    padding: 0 0 24px;
  }
}
