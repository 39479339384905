/* form style */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: transparent !important;
}

// standard
mat-form-field.mat-form-field.mat-form-field-appearance-standard {
  width: 100%;
  color: #fff;

  &.mat-form-field-no-label {
    .mat-form-field-infix {
      border-top: 0;
    }
    .mat-form-field-label-wrapper {
      top: 0;
      padding-top: 0;
    }
    .mat-form-field-flex {
      align-items: flex-end;
    }
  }

  .mat-button-wrapper {
    color: #fff;
  }

  input {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    &::placeholder {
      color: rgba($color: #ffffff, $alpha: 0.2);
    }
  }
  .mat-form-field-flex {
    padding-top: 0;
  }
  .mat-form-field-label-wrapper {
    transition: all 0.3s;
  }
  .mat-form-field-underline {
    // background-color: #fcfcfc;
  }
  &:not(.mat-form-field-invalid) {
    .mat-form-field-label {
      // color: rgba($color: #ffffff, $alpha: 0.2);
    }
    .mat-form-field-ripple {
      background-color: #ffffff;
    }
  }

  &:hover,
  &:focus,
  &.mat-focused {
    &:not(.mat-form-field-invalid) {
      // .mat-form-field-label {
      //   color: rgba($color: #ffffff, $alpha: 0.2);
      // }
      // .mat-form-field-ripple {
      //   background-color: #ffffff;
      // }
    }
    .mat-form-field-label-wrapper {
      background-color: rgba($color: #c4c4c4, $alpha: 0.2);
    }
  }
}

// outline
mat-form-field.mat-form-field.mat-form-field-appearance-outline {
  width: 100%;
  color: #fff;
  padding: 0;

  .mat-button-wrapper {
    color: #fff;
  }

  input {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    &::placeholder {
      color: rgba($color: #ffffff, $alpha: 0.2);
    }
  }

  .mat-select-arrow-wrapper {
    transform: translateY(0);

    .mat-select-arrow {
      color: #fff;
    }
  }
  .mat-form-field-prefix {
    top: -0.5em;
  }
  .mat-form-field-suffix {
    top: 0;
  }
  .mat-form-field-infix {
    padding: 0.75em 0;
  }
  .mat-form-field-outline {
    color: #fff;
    background-color: rgba($color: #fff, $alpha: 0.5);
  }
  &.mat-form-field-clean {
    .mat-form-field-outline {
      background-color: rgba($color: #fff, $alpha: 0.05);
    }
  }
}
mat-form-field.mat-form-field + mat-form-field.mat-form-field {
  margin-top: 10px;
}
.mat-form-field-no-error {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}
.mat-form-field-no-label {
  .mat-form-field-infix {
    border-top: 0;
  }
  .mat-form-field-label-wrapper {
    top: 0;
    padding-top: 0;
  }
  .mat-form-field-flex {
    align-items: flex-end;
  }
}
.mat-select-value {
  color: #fff;
}
.mat-label-fixed {
  display: block;
  color: #ffffff;
  margin-bottom: 5px;
}
mat-radio-group.mat-radio-group {
  color: #fff;

  mat-radio-button.mat-radio-button {
    margin: 0 10px;
    .mat-radio-outer-circle {
      border-color: #fff;
    }
  }
}

.m-modal-confirm {
  .delete-project-content {
    padding: 20px 0;
  }
  .delete-project-actions {
    padding-top: 20px;
    display: flex;
    justify-content: space-evenly;
    .delete,
    .cancel {
      border-radius: 25px;
    }
    .delete {
      background-color: rgba(255, 0, 0, 0.8);
      color: #fff;
      border: none;
      padding-left: 20px;
    }
  }
}
