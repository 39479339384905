body {
  background-color: #f4f4f4 !important;
  color: black;
}

div {
  color: black !important;
}

table {
  color: black;

  .mat-cell {
    color: black !important;
  }
}

a {
  color: black !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: black !important;
}

.mat-paginator .mat-button-wrapper,
.mat-paginator .mat-select-arrow {
  color: black !important;
}
.mat-date-range-input-mirror {
  color: black !important;
}
.mat-datepicker-toggle {
  .mat-button-wrapper {
    color: black !important;
  }
}
.container {
  display: block;
  position: relative;
  width: 100%;
  background: transparent;
  color: #545454;

  &.container-square {
    border-radius: 0;
  }

  .container-header {
    font-size: 1.25em;
    margin: 10px 0;
    padding: 0 10px;
    display: flex;
    align-items: flex-end;

    .m-container .m-container__body &{
      display: none;
    }

    .title {
      margin-right: 24px;
    }
    .info-text {
      font-size: 12px;
      color: #545454;
      align-self: flex-end;
      margin-left: 12px;
      margin-bottom: 0px;
      transform: translateY(-4px);
      th {
        color: #545454;
      }
      td {
        color: #545454;
      }
    }

    .dot {
      margin-left: 12px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
    .dot-bar {
      height: 8px;
      width: 100px;
      margin-left: 12px;
      border-radius: 4px;
    }
  }

  .container-body {
    border-radius: 15px;
    padding: 24px;
    background-color: white;
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.05));
    &.padding-0 {
      padding: 0;
    }
    &.padding-x-0 {
      padding-left: 0;
      padding-right: 0;
    }
    &.padding-y-0 {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .grow-title {
    display: block;
    border-left: 4px rgba($color: #ffffff, $alpha: 0.2) solid;
    padding-left: 1em;
    margin-bottom: 10px;
    color: #fff;
    text-shadow: 0px 0px 6px #00d1ff;
  }

  nz-table {
    color: red;
  }
}

.page-banner {
  background-image: url("/assets/media/layout/light-banner.png");
  height: 90px;
  display: flex;
  align-items: center;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: linear-gradient(
        90deg,
        #2964bc 0%,
        rgba(28, 151, 183, 0.510005) 56.77%,
        rgba(19, 159, 196, 0.510005) 100%
      ),
      linear-gradient(
        90deg,
        #6c8bbb 0%,
        rgba(83, 173, 197, 0.510005) 56.77%,
        rgba(36, 64, 71, 0) 89.41%
      );
  }
  .bar {
    position: relative;
    border-left: 5px rgba(255, 255, 255, 0.7) solid;
    padding-left: 30px;
    margin: 0;
  }
  span {
    position: relative;
    color: white;
  }
}

.table {
  table {
    width: 100%;
    background-color: transparent;
    th {
      color: #545454;
    }
    td {
      color: #545454;
    }
  }
}

.bg-lg-1 {
  background: linear-gradient(90deg, #9b70ca 0%, #bd4dd7 48.21%, #9b70ca 100%);
}

.bg-lg-2 {
  background: linear-gradient(90deg, #70e166 0%, #48ff86 48.21%, #70e166 100%);
}

.bg-lg-3 {
  background: linear-gradient(90deg, #8cf54b 0%, #b8ff22 48.21%, #82ff80 100%);
}

.bg-lg-4 {
  background: linear-gradient(90deg, #4bf5eb 0%, #22ffca 48.21%, #80f0ff 100%);
}

.bg-lg-5 {
  background: linear-gradient(90deg, #f5b14b 0%, #ff9a22 48.21%, #ffcc80 100%);
}

.bg-lg-6 {
  background: linear-gradient(90deg, #f54bef 0%, #ff22f6 48.21%, #ff80f2 100%);
}
.bg-lg-7 {
  background: linear-gradient(90deg, #4b66f5 0%, #22bdff 48.21%, #656cff 100%);
}

.bg-lg-8 {
  background: linear-gradient(90deg, #f54b4b 0%, #ff228c 48.21%, #ff8080 100%);
}

.bg-lg-9 {
  background: linear-gradient(90deg, #f5ef4b 0%, #fff622 48.21%, #fcff80 100%);
}
