mat-slider.mat-slider {
  &.mat-slider:hover .mat-slider-track-background {
    background-color: #eee;
  }
  .mat-slider-track-background {
    background-color: #bbb;
  }
  .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb {
    border-color: #fff !important;
  }
  .mat-slider-thumb {
    border-color: #ccc !important;
  }
}
