button {
  border: 0 #ccc solid;
  outline: 0;
}
button.btn {
  background-color: transparent;
  min-width: 166px;
  font-size: 1rem;
  padding: 0.5rem 0.5rem;
  border-radius: 999px;
  border: 2px $color-primary solid;
  color: $color-secondary;
  text-align: center;
  cursor: pointer;

  transition: all 0.3s;

  + button.btn {
    margin-left: 10px;
  }

  &.btn-primary {
    background: linear-gradient(262deg, #0173d5 34%, #015faf 66%);
    border-color: transparent;
    color: #fff;

    &:hover {
      background: linear-gradient(261.63deg, #0089ff 33.39%, #0089ff 65.66%);
    }
  }

  &.btn-empty {
    min-width: unset;
    border: none;
    border-radius: 3px;
  }

  &.btn-secondary {
    background: rgba($color: #242936, $alpha: 0.8);
    border-color: rgba(255, 255, 255, 0.8);
    color: rgba(255, 255, 255, 0.8);

    &:hover {
      background: rgba($color: #2c3447, $alpha: 0.8);
      border-color: rgba(255, 255, 255, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
  &.btn-longer {
    min-width: 230px;
  }
  &.btn-minimum {
    min-width: auto;
    padding-left: 1em;
    padding-right: 1em;
  }

  &:disabled {
    filter: grayscale(0.5) brightness(0.8);
    pointer-events: none;
    cursor: auto;
  }
  &:hover {
    background-color: rgba($color: #fff, $alpha: 0.2);
    color: #fff;
    border-color: #fff;
  }
  &:active {
    filter: brightness(0.8);
  }
}

.m-button-wrap {
  display: flex;
}

.m-button {
  // border: 1px solid map-get($colors, primary);
  // border-radius: 15px;
  // background-color: map-get($bg-colors, primary);
  // color: map-get($colors, priamry);
  // line-height: 30px;
  // transition: all ease 0.8s;
  // padding: 0px 10px;
  // @include dis(middle);
  // &:hover {
  //   background-color: map-get($bg-colors, hover);
  //   color: map-get($fonts, hover);
  // }
  height: 30px;
  border: 1px solid white;
  padding: 0px 12px;
  border-radius: 20px;
  line-height: 30px;
  padding: 0px 10px;
  cursor: pointer;
  @include dis(middle);
  &.m-button-create {
    height: 30px;
    border: 1px solid white;
    padding: 0px 12px;
    border-radius: 20px;
    line-height: 30px;
  }
  &.m-button-edit {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: 1px solid #ccc;
    margin-right: 3px;
    height: 30px;
    .blur-table .mat-table .mat-cell & {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      font-size: 15px;
      z-index: 999;
    }
  }
  &.m-button-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: 1px solid #ccc;
    margin-right: 3px;
    height: 30px;
  }
  &.m-button-view {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: 1px solid #ccc;
    margin-right: 3px;
    height: 30px;
  }
}
