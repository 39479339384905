.m--border {
  border: 1px solid map-get($colors, borderWrap);
}

.m--border-radius {
  border-radius: 15px;
}

.m--padding-lr {
  padding-left: 15px;
  padding-right: 15px;
}
