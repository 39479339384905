.dark {
  div {
    color: white !important;
  }

  .mat-form-field-label {
    color: white !important;
  }

  .mat-select-placeholder {
    color: white !important;
  }

  table {
    color: white;

    .mat-cell {
      color: white !important;
    }
  }
  a {
    color: white !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white !important;
  }

  .mat-paginator .mat-button-wrapper,
  .mat-paginator .mat-select-arrow {
    color: white !important;
  }
  .mat-date-range-input-mirror {
    color: white !important;
  }
  .mat-calendar-table-header {
    color: white;
  }
  .mat-calendar {
    background-color: #282e3e;
  }
  .mat-calendar-header {
    background-color: #282e3e;
  }
  .mat-calendar-content {
    background-color: #282e3e;
  }
  .mat-calendar-arrow {
    border-top-color: white;
    // color: white !important;
  }
  .mat-calendar-previous-button {
    color: white;
  }
  .mat-calendar-next-button {
    color: white;
  }
  .mat-calendar-body-label {
    color: white;
  }
  .mat-datepicker-toggle {
    .mat-button-wrapper {
      color: white !important;
    }
  }
  background-color: #282e3e !important;
  .container {
    color: white;
    .info-text {
      color: white;
    }
    .container-body {
      background-color: #20282e;
      box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(10px);
    }
  }
  .page-banner {
    background-image: url("/assets/media/layout/dark-banner.png");
    height: 90px;
    display: flex;
    align-items: center;
    &::before {
      display: none;
    }
    .bar {
      border-left: 5px #d2f2ff solid;
      padding-left: 30px;
      margin: 0;
      h1 {
        margin: 0;
      }
    }
    span {
      color: #d2f2ff;
    }
  }
  .quick-portal {
    .quick-portal-links {
      .portal-button {
        background: transparent;
        border-radius: 10px;
        border: 2px solid rgba(255, 255, 255, 0.25);
      }
      span {
        color: white;
        opacity: 1;
      }
    }
  }

  .table {
    table {
      th {
        color: white;
      }
      td {
        color: white;
      }
    }
  }
  .ant-modal-body {
    background-color: #20282e;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
  }
  .ant-modal-footer {
    background-color: #20282e;
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
  }
  .ant-modal-close-icon {
    color: white;
  }
  .ant-modal-header {
    background-color: #20282e;
  }

  .cke_dialog_body {
    * {
      color: #000 !important;
    }
  }
}
