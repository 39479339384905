.mat-paginator {
  background-color: transparent;

  .mat-button-wrapper,
  .mat-select-arrow {
    color: rgba($color: #ffffff, $alpha: 0.8)
  }
  .mat-form-field {
    &.mat-focused {
      .mat-button-wrapper,
      .mat-select-arrow {
        color: rgba($color: #ffffff, $alpha: 1)
      }
    }
  }
}
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: #ffffff;
}
